/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Suspense, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import './reset.css';
import './assets/css/font-awesome.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/css/styles.css';
import Spinner from './shared/Spinner';
import { AuthProvider } from './AuthProvider';
import { AppConsumer, AppProvider } from './app-context';
import { ScrollPanel } from 'primereact/scrollpanel';
// import css from 'styled-jsx/css';

function App() {
	const location = useLocation();
	const absoluteBasePath = '/admin';
	const [isFullPageLayout, setisFullPageLayout] = useState(false);

	const onRouteChanged = () => {
		const pathName = location.pathname.replace(absoluteBasePath, '');
		console.log(pathName);
		const body = document.querySelector('body');
		if (pathName === '/layout/RtlLayout') {
			body?.classList.add('rtl');
		} else {
			body?.classList.remove('rtl');
		}
		window.scrollTo(0, 0);
		const fullPageLayoutRoutes = ['/login', '/register', '/forgot', '/404', '/500', '/chipinfo', '/chipinfo/:shortkey'];
		for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
			if (pathName === fullPageLayoutRoutes[i] || pathName.indexOf('/chipinfo') > -1) {
				setisFullPageLayout(true);
				document.querySelector('.page-body-wrapper')?.classList.add('full-page-wrapper');
				break;
			} else {
				setisFullPageLayout(false);
				document.querySelector('.page-body-wrapper')?.classList.remove('full-page-wrapper');
			}
		}
	};

	useEffect(() => {
		onRouteChanged();
	}, [location]);

	const navbarComponent = !isFullPageLayout ? <Navbar /> : '';
	const sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
	return (
		<AuthProvider>
			<AppProvider>
				<AppConsumer>
					{() => (
						<div className='container-scroller'>
							{navbarComponent}
							<div className='container-fluid page-body-wrapper'>
								{sidebarComponent}
								<Suspense fallback={<Spinner />}>
									<ScrollPanel
										style={{
											width: '100%',
											height: '100vh'
										}}
										className='purpleScrollbar'>
										<div className='main-panel'>
											<div className='content-wrapper'>
												<Outlet />
											</div>
										</div>
									</ScrollPanel>
								</Suspense>
							</div>
						</div>
					)}
				</AppConsumer>
			</AppProvider>
		</AuthProvider>
	);
}

export default App;
